header {
  width: 100%;
}

a:hover,
a:active,
a {
  text-decoration: none;
  cursor: pointer;
  color: #898d8d;
}

.faq-container {
  border: solid;
  border-width: thick;
  border-color: #f2f2f2;
}

.nowrap {
  white-space: nowrap;
}

.list-circle {
  list-style: circle;
}

.phoneDigitsFields {
  margin-top: 20px;
  flex-wrap: nowrap;
}

.validationFailed {
  color: #ff3333;
  margin-top: 10px;
}

.noborderradius div {
  border-radius: 0px;
}

.noborderradius input {
  max-width: 48px;
  padding: 18.5px 0px;
  text-align: center;
  font-size: 1.5em;
}

@font-face {
  font-family: 'ProximaNovaExCnLight';
  src: url('shared/webfonts/proximanovaexcn-lightweb.woff');
  src: url('shared/webfonts/proximanovaexcn-lightweb.woff') format('embedded-opentype'),
    url('shared/webfonts/proximanovaexcn-lightweb.woff') format('woff');
}
@font-face {
  font-family: 'ProximaNovaExCnSbold';
  src: url('shared/webfonts/proximanovaexcn-sboldweb.woff');
  src: url('shared/webfonts/proximanovaexcn-sboldweb.woff') format('embedded-opentype'),
    url('shared/webfonts/proximanovaexcn-sboldweb.woff') format('woff');
}
@font-face {
  font-family: 'ProximaNovaExCnSReg';
  src: url('shared/webfonts/proximanovaexcn-regweb.woff');
  src: url('shared/webfonts/proximanovaexcn-regweb.woff') format('embedded-opentype'),
    url('shared/webfonts/proximanovaexcn-regweb.woff') format('woff');
}
@font-face {
  font-family: 'ProximaNovaReg';
  src: url('shared/webfonts/proximanova-regweb.eot');
  src: url('shared/webfonts/proximanova-regweb.eot?iefix') format('embedded-opentype'),
    url('shared/webfonts/proximanova-regweb.woff') format('woff');
}
