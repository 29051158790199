footer {
  width: 100%;
}

.footer-links-mobile {
  display: block;
}

.footer-links-no-mobile {
  display: none;
}

.footer-links-no-mobile .red-divider {
  margin-bottom: 2em;
}

.footer-links-mobile .MuiDivider-root {
  margin-bottom: 1em;
}

footer a,
footer a:hover {
  text-decoration: none;
}

.footer-links-mobile > div:last-child {
  margin-bottom: 10px;
}

.footer-links-mobile-iconbar i {
  color: #ff3333;
  font-size: 2em;
}

@media screen and (min-width: 600px) {
  .footer-links-mobile {
    display: none;
  }

  .footer-links-no-mobile {
    width: 100%;
    display: block;
  }

  .footer-links-no-mobile .row {
    width: 100%;
  }

  .footer-links-no-mobile i {
    min-width: 16px;
    text-align: center;
    color: #ff3333;
    line-height: 1.5em;
  }

  .footer-links-no-mobile li {
    padding-top: 0.25em;
  }

  .footer-links-no-mobile li.footer-head {
    border-top: 2px grey solid;
    border-bottom: 1px grey solid;
    padding-bottom: 0.5em;
  }
}

@media screen and (min-width: 1200px) {
  .footer-links-no-mobile .container-fluid {
    margin: auto;
    max-width: 1100px;
  }
}
