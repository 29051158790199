.infobox-icon-container > .licenseplate > .licenseplate-text {
  text-align: center;
  left: 15px;
}

.infobox-icon-container > .licenseplate-centered > .licenseplate-text {
  text-align: center;
  left: 15px;
}

.smaller-picture {
  transform: scale(0.7, 0.7);
}

.picture-margin {
  margin-top: 20px;
  margin-bottom: 5px;
}

.invisible {
  display: none;
}

.carousel-arrows {
  cursor: pointer;
  color: #898d8d;
}

.carousel-arrows:hover {
  color: #ff3333;
}

.carousel-container > div {
  height: 100%;
}

.BrainhubCarousel {
  height: 90%;
}

.BrainhubCarousel__dots .BrainhubCarousel__dot.BrainhubCarousel__dot--selected::before {
  background: #ff3333;
}

.carousel-request-status {
  display: block;
  border-radius: 50%;
  height: 1rem;
  width: 1rem;
  margin-left: 23px;
  margin-bottom: 15px;
}

/* size: small (vertical Tablet) */
@media screen and (min-width: 600px) {
  .carousel-request-status {
    margin-left: 20px;
  }
}

/* size: medium (horizontal Tablet) */
@media screen and (min-width: 768px) {
  .carousel-request-status {
    margin-left: 28px;
  }
}

/* size: large (Laptop / PC) */
@media screen and (min-width: 1200px) {
  .carousel-request-status {
    margin-left: 30px;
  }
}
