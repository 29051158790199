.licenseplate {
  transform: scale(0.55, 0.55) translate(-40px,0px);
}

.licenseplate-centered {
  transform: scale(0.7, 0.7);
}

.licenseplate-text {
  position: absolute;
  left: 40px;
  right: 0;
  margin: auto;
  width: 100%;
  height: 100%;
}

.licenseplate-text p {
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 3.5rem !important;
  white-space: nowrap;
}

@media screen and (min-width: 325px) {
  .licenseplate {
    transform: scale(0.7, 0.7) translate(-35px,0px);
  }
  
  .licenseplate-text {
  left: 35px;
}
}

@media screen and (min-width: 768px) {
  .licenseplate {
    transform: none;
  }

  .licenseplate-centered {
    transform: none;
  }

  .licenseplate-text {
    left: 15px;
  }

  .licenseplate-text p {
    font-size: 1.7rem;
    line-height: 3.5rem !important;
    white-space: nowrap;
  }
}
