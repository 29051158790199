.request-status {
  display: block;
  border-radius: 50%;
  height: 1rem;
  width: 1rem;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 15px;
}

.request-status-active {
  background-color: rgb(0, 194, 0);
}

.request-status-not-active {
  background-color: orange;
}

#request-car-image {
  margin-top: 10px;
  margin-bottom: 10px;
}

.request-mobile {
  display: block;
}

.request-desktop {
  display: none;
}

.MuiAccordionSummary-content > p {
  font-size: 1.2em;
}

@media screen and (min-width: 700px) {
  .request-mobile {
    display: none;
  }

  .request-desktop {
    display: block;
  }
}
