.innerForm {
  font-family: Tahoma, Arial, Helvetica, sans-serif;
}

.pw-validation-notification {
  font-size: smaller;
  line-height: 14px !important;
}

.unsafe {
  color: #ff3333;
}

.okay {
  color: #f2b90d;
}

.safe {
  color: #34b234;
}

#pwStrengthIndicator {
  font-size: 20px;
}

#pwStrengthIndicator i + i {
  margin-left: 10px;
}

.form-group {
  margin-bottom: 3px;
}
