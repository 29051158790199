.MuiAccordionDetails-root {
  display: block;
}

.MuiAccordion-root:before {
  background-color: transparent;
}

.MuiAccordion-root.Mui-expanded {
  margin-top: 0px;
}

.MuiAccordion-root.Mui-expanded:last-child {
  margin-bottom: 10px;
}

.MuiInput-underline.Mui-disabled:before {
  border-bottom-style: solid;
}

.MuiInputBase-root.Mui-disabled {
  color: #898d8d;
}

.profileInputField {
  width: 100%;
  margin-bottom: 20px;
}

.profile-images {
  display: none;
  margin: auto;
}

.cityValidationError {
  color: #f44336;
  margin: 0;
  font-size: 0.75rem;
  margin-top: 3px;
  text-align: left;
  font-family: Tahoma, Arial, Helvetica, sans-serif;
  font-weight: 400;
  line-height: 1.66;
}

.loading-progress {
  width: 20px !important;
  height: 20px !important;
  margin: 11px !important;
}

.smaller-picture-top {
  transform: scale(0.7, 0.7);
  transform-origin: top;
}

.edit-icon {
  color: #c2c2c2;
}

.select-input-label {
  margin-bottom: 0px !important;
}

@media screen and (min-width: 600px) {
  .profile-images {
    display: block;
  }
}
