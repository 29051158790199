ul {
  list-style: none;
}

.underline {
  text-decoration: underline !important;
}

.disc {
  list-style: disc !important;
  color: #898d8d;
}

.portal-session-container {
  width: 100%;
  height: 100%;
}

.session-refresh {
  cursor: pointer;
}

.session-refresh-dialog .MuiDialog-paperWidthSm {
  width: 350px;
}

.loading-center {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: 10em;
}

.container-mobile-visible {
  display: block;
}

.container-desktop-visible {
  display: none;
}

.portal-header-bg {
  background-color: white;
}

.portal-header {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  z-index: 100;
}

.portal-header-end {
  flex-direction: column;
  margin-left: auto;
}

.portal-nav {
  flex-direction: row;
}

.portal-nav > .portal-nav-link {
  margin-left: 1.2em;
}

.portal-subnav-link {
  padding-right: 15px;
}

.portal-nav-link:hover > p {
  color: #ff3333;
}

.portal-nav-link.active {
  background-color: #f2f2f2;
}

.mobile-sub-nav,
.mobile-sub-nav:hover {
  text-decoration: none;
}

.mobile-sub-nav:hover > p {
  color: #ff3333;
}

.portal-nav-link.active > p {
  color: #ff3333;
}

.portal-nav-link.active > .portal-nav-icon {
  color: #ff3333;
}

.portal-nav-link {
  display: flex;
}

.portal-sub-nav {
  flex-direction: row;
  background-color: transparent;
  justify-content: flex-end;
}

.portal-sub-nav > a:first-child > p {
  color: #ff3333;
}

.portal-sub-nav > a {
  margin-top: 10px;
}

.portal-sub-nav > a > p {
  font-size: 0.9em;
}

.nav-button {
  font-size: xx-large;
  color: #898d8d;
  margin-bottom: 50%;
}

.mobile-open-nav {
  padding-right: 0px;
  padding-left: 0px;
}

.mobile-nav-point {
  background-color: white;
  padding-top: 22px;
  padding-bottom: 3px;
  padding-left: 15px;
  padding-right: 15px;
}

.MuiDrawer-paperAnchorTop {
  height: 100%;
}

.button-and-text {
  display: flex;
  flex-direction: row;
}

.flex-start {
  align-self: flex-start;
}

.smaller-fontsize {
  font-size: smaller;
}

.logout {
  font-size: 1rem;
  cursor: pointer;
}

.mobile-nav-point.mobile-logout {
  background-color: #ff3333;
  display: flex;
}

.regular-line-height,
.regular-line-height span {
  line-height: 1.5em !important;
}

.logout-logo {
  color: #898d8d;
  margin-left: auto;
}

.mobile-nav-point.mobile-logout > p {
  color: white;
}

.mobile-nav-point.mobile-logout > .logout-logo {
  color: white;
}

.portal-nav-icon {
  margin-left: auto;
  color: #898d8d;
}

.mobile-sub-nav {
  padding-left: 30px;
  padding-top: 10px;
  display: flex;
}

.mobile-sub-nav_1 {
  padding-left: 15px;
}

.mobile-sub-nav_2 {
  padding-left: 30px;
}

.mobile-sub-nav_3 {
  padding-left: 45px;
}

.mobile-sub-nav_4 {
  padding-left: 60px;
}

.red-divider {
  background-color: #ff3333;
  height: 5px;
  width: 100%;
}

.portal {
  max-width: 100%;
  margin-bottom: 10px;
}

main {
  width: 100%;
}

.background-picture {
  width: 100%;
}

.startpage-overlay-car {
  background-image: url('../../../resources/images/honda_car_background.jpeg');
  background-repeat: no-repeat;
  background-size: 100%;
  padding-top: 45%;
}

.startpage-overlay-bike {
  background-image: url('../../../resources/images/honda_bike_background.jpeg');
  background-repeat: no-repeat;
  background-size: 100%;
  padding-top: 45%;
}

.startpage-info-container {
  padding-right: 1em;
  padding-left: 1em;
  margin-bottom: 0.5em;
  min-height: 15em;
}

.container-with-border {
  background-color: white;
  border: solid;
  border-width: thick;
  border-color: #f2f2f2;
}

.content-container {
  margin-bottom: 16px;
  margin-left: auto !important;
  margin-right: auto !important;
  width: 98%;
}

.content-container .MuiTypography-root {
  line-height: 2rem;
}

.infobox-icon {
  height: 70px;
}

.infobox-icon-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

div.align-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.bold {
  font-weight: bold;
}

.text-margin {
  margin-bottom: 0.5em;
}

.mobile-smaller {
  font-size: medium;
}

.infobox-divider-row {
  padding-top: 1em;
  padding-bottom: 1em;
}

div > :first-child.infobox-divider {
  padding-top: 4px;
  padding-right: 0px;
}

div > :last-child.infobox-divider {
  padding-top: 4px;
  padding-left: 0px;
}

.box-link:hover {
  text-decoration: none;
}

.not-mobile-flex {
  display: none;
}

.not-mobile-block {
  display: none;
}

.startpage-container-row > :first-child,
.startpage-container-row > :last-child {
  padding-left: 2%;
  padding-right: 2%;
}

.logo {
  height: auto;
  margin-top: 1em;
  margin-bottom: 1em;
  margin-left: 0.3em;
  max-width: 9em;
}

.back-to-top {
  position: fixed;
  padding: auto;
  right: 40px;
  bottom: 50px;
  width: 45px;
  height: 45px;
  color: #ff3333;
  border: 1px solid rgba(0, 0, 0, 0.12);
  background-color: white;
  text-align: center;
  cursor: pointer;
  font-size: 1.5em;
  z-index: 100;
}

.full-height {
  height: 100%;
}

.vertical-center {
  display: flex;
  align-items: center;
  text-align: center;
}

.horizontal-center {
  display: flex;
  justify-content: center;
}

.small {
  font-size: 0.75em;
}

.big {
  font-size: 1.5em;
}

.benefits .row {
  margin-bottom: 20px;
}

.MuiAccordionSummary-root {
  padding: 0 15px 0 15px;
}

.MuiAccordionDetails-root {
  padding: 8px 15px 15px;
}

.section-heading {
  margin-left: 25px;
  color: #ff3333;
}

.link-no-decoration,
.link-no-decoration :hover,
.link-no-decoration :active {
  text-decoration: none !important;
}

.contentContainer {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

.contentContainer .MuiPaper-elevation1 {
  box-shadow: none;
}

.divider-1 {
  height: 1px;
}

.divider-2 {
  height: 2px;
}

.divider-3 {
  height: 3px;
}

.MuiDialog-paper {
  border-radius: 0px !important;
  border: thick solid #f2f2f2;
}

.BrainhubCarousel__dot {
  background-color: white;
}

.padding-1 {
  padding: 1em;
}

@media screen and (min-width: 600px) {
  .startpage-info-container {
    margin-bottom: 1.5em;
  }

  .startpage-overlay {
    padding-top: 45%;
  }

  .mobile-smaller {
    font-size: smaller;
  }

  .container {
    max-width: none;
  }

  .startpage-container-row {
    padding-left: 4%;
    padding-right: 4%;
  }
}

@media screen and (min-width: 768px) {
  .fourColumnRow :nth-child(4n - 1) {
    padding-left: 40px;
    white-space: nowrap;
  }

  .container-mobile-visible {
    display: none;
  }

  .container-desktop-visible {
    display: block;
  }

  .portal {
    margin-bottom: 0px;
  }

  .logout-logo {
    padding-right: 2px;
    font-size: 1em;
    margin-top: auto;
    margin-bottom: auto;
  }

  .logo {
    margin-top: 1.2em;
    margin-left: 0.5em;
    max-width: 10em;
  }

  .mobile-nav-drawer {
    display: none;
  }

  .portal-header-bg {
    background-color: #f2f2f2;
    padding-bottom: 1em;
  }

  .mobile-smaller {
    font-size: medium;
  }

  .not-mobile-flex {
    display: flex;
  }

  .not-mobile-block {
    display: block;
  }

  .mobile-nav {
    display: none;
  }

  .portal-nav > .portal-nav-link {
    margin-left: 5vw;
  }

  .portal-nav-link > p {
    font-size: 1.2rem;
  }

  .contentContainer {
    width: 700px;
  }

  .contentContainer-wide {
    width: 100% !important;
    max-width: 1600px !important;
  }
}

@media screen and (min-width: 1200px) {
  .startpage-overlay-car {
    padding-top: 35%;
  }

  .portal-header-bg {
    background-color: #f2f2f2;
  }

  .logo {
    max-width: 20em;
  }

  .infobox-content {
    margin-left: 1em;
  }

  .infobox-icon {
    height: 75%;
  }

  .infobox-icon-container {
    height: 33%;
  }

  .startpage-overlay {
    padding-top: 45%;
  }

  .startpage-info-container {
    height: 20em;
    margin-bottom: 3em;
  }

  .background-picture {
    height: 80%;
    object-fit: cover;
    object-position: top center;
  }

  .portal.container {
    max-width: 100%;
  }

  .container {
    max-width: 100%;
  }

  .startpage-container-row {
    padding-left: 10%;
    padding-right: 10%;
  }

  .startpage-container-row > :first-child {
    padding-right: 5%;
  }

  .startpage-container-row > :last-child {
    padding-left: 5%;
  }

  .mobile-smaller {
    font-size: 1.1rem;
  }

  .portal-nav > .portal-nav-link {
    margin-left: 7vw;
  }

  .portal-nav-link > p {
    font-size: 1.5rem;
  }
}
