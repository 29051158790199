.image-test {
  color: #898d8d;
  font-size: 3em;
}

.MuiStepLabel-labelContainer .MuiTypography-root {
  line-height: 1rem;
  font-size: 0.7em;
}

.MuiStepIcon-root.MuiStepIcon-active {
  color: #ff3333;
}

.MuiStepLabel-label.MuiStepLabel-active {
  font-size: 1.5em;
  line-height: 1.7rem;
  font-weight: bold;
  color: #898d8d;
}

.email-reset-inner-box {
  margin-left: 0px;
}

.email-reset-textbox {
  margin-top: 3em;
  margin-bottom: 1.5em;
}

.email-reset-header {
  margin-top: 1rem;
  line-height: 1.5rem !important;
}

.MuiStepper-root.email-reset {
  padding: 0px 10px 10px;
}

.margin-auto {
  margin: auto;
}

.mobile-margin-bottom {
  margin-bottom: 1em;
}

.account-button {
  width: 65%;
}

#faq .MuiButtonBase-root {
  cursor: auto;
}

@media screen and (min-width: 768px) {
  .MuiStepper-root.email-reset {
    padding: 0px 24px 24px;
  }

  .email-reset-inner-box {
    width: 80%;
  }

  .mobile-margin-bottom {
    margin-bottom: 0em;
  }
}
