.upload-text {
  color: #ff3333;
  font-weight: bold;
}

.upload-file-picker {
  border-style: dashed;
  border-width: thin;
  border-color: #898d8d;
  padding: 10px;
  color: #898d8d;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.upload-file-row {
  display: flex;
  flex-direction: row;
  background-color: #f2f2f2;
  border-width: 1px;
  border-style: solid;
  border-color: #e3e3e3;
}

.upload-file-name {
  margin-left: 3px;
  margin-right: 10px;
}

.upload-file-size {
  margin-right: auto;
  background-color: grey;
  border-radius: 10px;
  margin-top: 4px;
  margin-bottom: 4px;
  padding-right: 8px;
  padding-left: 8px;
}

.upload-file-valid {
  margin-right: 5px;
  margin-left: auto;
  margin-top: 3px;
  margin-bottom: 3px;
  background-color: lightgreen;
  padding-right: 4px;
  padding-left: 4px;
  border-radius: 2px;
}

.upload-file-invalid {
  margin-right: 5px;
  margin-left: auto;
  margin-top: 3px;
  margin-bottom: 3px;
  background-color: red;
  padding-right: 4px;
  padding-left: 4px;
  border-radius: 2px;
}

.upload-file-delete {
  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: #d9d9d9;
  background-color: #e9e9e9;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: fit-content;
  padding-right: 4px;
  padding-left: 4px;
  margin-right: 0px;
}

.upload-file-delete:hover {
  cursor: pointer;
}

.no-uppercase {
  text-transform: inherit;
}

.upload-file-picker > :first-child {
  margin-bottom: 1.5em;
}

.upload-file-picker-button {
  margin-top: 1em;
  border-style: solid;
  border-color: #f2f2f2;
  border-width: 1px;
  padding-right: 10px;
  padding-left: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.upload-file-picker-button:hover {
  background-color: #898d8d;
}

.upload-file-picker-button:hover > p {
  color: white;
}

.upload-file-picker:hover {
  cursor: pointer;
}

.upload-component {
  margin-bottom: 10px;
}

.upload-header {
  font-weight: bold;
  font-size: 1.2em;
}

.upload-file-size-text {
  color: white;
  font-size: 0.6em;
}

.upload-file-invalid-text {
  font-size: 0.7em;
  color: white;
}

.upload-file-valid-text {
  font-size: 0.7em;
  color: white;
}

.upload-component .MuiTypography-root {
  line-height: 1rem;
}

.upload-button {
  color: #ff3333;
  font-size: 1.5rem;
  font-weight: bolder;
  cursor: pointer;
  margin: 10px 0 10px 0;
  display: flex;
  align-items: center;
}

.upload-button > i {
  font-size: 2rem;
  margin-right: 10px;
}
