.message-action-disabled {
  color: #ced6d6;
}

.message-action,
.download-action {
  cursor: pointer;
}

button {
  background-color: #ff3333;
}

.subheading-message {
  margin-left: 25px;
}
