.red-triangle-small {
  width: 50%;
  height: 50%;
}

.red-triangle-medium {
  width: 75%;
  height: 75%;
}

.red-triangle-large {
  width: 100%;
  height: 100%;
}
