.root {
  margin: 0;
  padding: 0;
  height: 100vh;
}

button.square-button {
  border-radius: 0px;
  min-width: 45%;
  box-shadow: 0px 0px 0px 0px;
}

button.square-button:hover {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14),
    0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: #ff3333;
}

button.white-square-button {
  border-radius: 0px;
  min-width: 45%;
  box-shadow: 0px 0px 0px 0px;
}

button.white-square-button:hover {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14),
    0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
}

button.bordered-button {
  border-style: solid;
  border-width: 1px;
}

button.red-border {
  border-style: solid;
  border-width: 1px;
  border-color: #ff3333;
}

button.red-border.Mui-disabled {
  border-color: rgba(0, 0, 0, 0.12);
}

.MuiButton-contained {
  box-shadow: 0px;
}

label {
  color: #898d8d;
  font-size: smaller;
}

.no-line-height {
  line-height: 1;
}

.sidebar {
  margin-right: 3em;
}

.margin {
  margin: 0.75em;
}

.margin1 {
  margin: 1.5em;
}

.margin2 {
  margin: 2.5em;
}

.textField {
  width: 300;
}

.align-left {
  align-self: flex-start;
}

.underline {
  text-decoration: underline;
}

.registrationBox {
  position: relative;
  min-height: 100%;
  width: 100%;
}

.paper {
  padding: 0.7em;
  margin-bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.innerForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.inline {
  flex-direction: row;
  display: flex;
}

.inline > button:first-child {
  margin-left: 0px;
}

.inline button + button {
  margin-left: auto;
}

.registration-navigation {
  padding-top: 20px;
  display: flex;
}

.registration-navigation :first-child {
  margin-left: 0px;
  margin-right: auto;
}

.registration-navigation :last-child {
  margin-left: auto;
  margin-right: 0px;
}

.infoText {
  font-size: 10px;
}

.background-container-car {
  height: auto;
  width: 100%;
}

.background-container-bike {
  height: auto;
  width: 100%;
}

.form-logo {
  max-width: 20em;
  height: auto;
  margin-top: 1em;
}

.invalid-feedback {
  color: red;
  font-size: 12px;
}

.inner-form {
  height: 100%;
  padding-bottom: 4em;
}

.footer {
  width: 100%;
  text-align: center;
  padding-right: 0.5em;
  padding-left: 0.5em;
  z-index: 100;
  background-color: white;
  position: absolute;
  bottom: 0;
}

.inline > p + p + p {
  margin-right: 0px;
  margin-left: 0px;
}

.inline > p + p {
  margin-left: auto;
  margin-right: auto;
}

a.grey {
  color: #898d8d;
}

.red {
  color: #ff3333;
}

.password-hide-container {
  padding-top: 2em;
  padding-left: 6px;
  color: #898d8d;
}

.password-hide-container > i {
  cursor: pointer;
}

.full-width {
  width: 100%;
}

.lower-case {
  text-transform: initial;
}

.icon-and-text {
  padding-bottom: 15px;
}

.icon-and-text > p {
  margin-left: 15px;
}

.registrationInputField {
  margin-top: 0.5rem;
  width: 100%;
}

.registrationInputField .MuiInputBase-root:not(.Mui-error) {
  margin-bottom: 1.4em;
}

.buttonLink:hover {
  color: #fff;
  background-color: #ff3333;
}

@media screen and (min-width: 37.5em) {
  .margin {
    margin: 1em;
  }

  .margin1 {
    margin: 2em;
  }

  .margin2 {
    margin: 3em;
  }

  .registrationBox {
    margin-right: 15%;
    margin-left: 15%;
    right: 1em;
    top: 1em;
    left: 1em;
    border-style: solid;
    border-width: thick;
    border-color: #f2f2f2;
    max-width: 70%;
  }

  .paper {
    margin: 2em;
    padding: 1em;
    margin-bottom: 4em;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

@media screen and (min-width: 60em) and (orientation: landscape) {
  .background-container-car {
    background: url(../../../resources/images/honda_car_background.jpeg);
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    height: auto;
    width: 100%;
  }

  .background-container-bike {
    background: url(../../../resources/images/honda_bike_background.jpeg);
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    height: auto;
    width: 100%;
  }

  .registrationBox {
    margin-right: 0px;
    margin-left: 0px;
    position: absolute;
    right: 2em;
    bottom: 2em;
    top: 1em;
    left: auto;
    max-width: 35%;
    min-height: 660px;
    border-style: solid;
    border-width: thick;
    border-color: #f2f2f2;
  }
}

@media screen and (min-width: 65em) and (orientation: landscape) {
  .background-container-car {
    background: url(../../../resources/images/honda_car_background.jpeg);
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    height: auto;
    width: 100%;
  }

  .background-container-bike {
    background: url(../../../resources/images/honda_bike_background.jpeg);
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    height: auto;
    width: 100%;
  }

  .margin {
    margin: 1em;
  }

  .margin1 {
    margin: 2em;
  }

  .margin2 {
    margin: 3.5em;
  }

  .registrationBox {
    margin-right: 0px;
    margin-left: 0px;
    position: absolute;
    right: 2em;
    bottom: 2em;
    top: 15%;
    left: auto;
    min-width: 35vh;
    max-width: 350px;
    min-height: 660px;
    border-style: solid;
    border-width: thick;
    border-color: #f2f2f2;
  }

  .paper {
    margin: 0em;
    margin-top: 1em;
    padding: 1em;
    padding-top: 0;
    margin-bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
